var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('sb-loading'):_vm._e(),(!_vm.loading && !_vm.school)?_c('div',[_vm._v("Geen school gevonden")]):_vm._e(),(_vm.school)?[_c('sb-page-header',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('h1',[_vm._v(" "+_vm._s(_vm.school.name)+" "),_c('small',[_vm._v("School")]),(!_vm.school.active)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" (Gedeactiveerd) ")]):_vm._e()])]},proxy:true},{key:"right",fn:function(){return [(_vm.$user.role !== 'MANAGER')?_c('dropdown',{attrs:{"placement":"bottom-end"}},[_c('i-button',{attrs:{"type":"primary","ghost":""}},[_vm._v(" Acties "),_c('icon',{attrs:{"type":"md-more"}})],1),_c('dropdown-menu',{staticStyle:{"width":"220px"},attrs:{"slot":"list"},slot:"list"},[(
                _vm.$user.context.isAdmin ||
                (_vm.$user.role === 'MANAGER' &&
                  _vm.$user.resourceType === 'SCHOOL_DISTRICT')
              )?_c('router-link',{attrs:{"to":{
                name: 'ResourceManagementSchoolsSchoolAddEdit',
                params: {
                  schoolManageId: _vm.school.id,
                  resourceType: 'admin',
                  resourceId: 'default',
                  organisationType: 'school',
                  manageMethod: 'edit',
                },
              }}},[_c('sb-dropdown-button',[_vm._v(" Gegevens bewerken ")])],1):_vm._e(),(
                _vm.$user.role === 'ADMIN' ||
                (_vm.$user.role === 'MANAGER' &&
                  _vm.$user.resourceType === 'SCHOOL_DISTRICT')
              )?_c('sb-dropdown-button',{staticStyle:{"color":"red"},nativeOn:{"click":function($event){return _vm.deleteSchool(_vm.school.id)}}},[_vm._v(" School verwijderen ")]):_vm._e()],1)],1):_vm._e()]},proxy:true}],null,false,1099318031)}),(_vm.school)?_c('sb-tabbed-page',{attrs:{"data":_vm.school},on:{"refetch":_vm.refetch}}):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }