<template>
  <div
    class="sb_list"
    :class="{ 's_hide-background' : hideBackground }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SbList',
  props: {
    hideBackground: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
  .sb_list {

    &:not(.s_hide-background ){
      .sb_list-item {
        background: $brand-white;
        &:nth-child(even) {
          background: $brand-lightest-gray;
        }
      }
    }
  }
</style>
