<template>
  <div>
    <sb-loading v-if="loading" />
    <div v-if="!loading && !schooldistrict">Geen Schooldistrict gevonden</div>
    <template v-if="schooldistrict">
      <sb-page-header>
        <template v-slot:left>
          <h1>
            {{ schooldistrict.name }} <small>Schooldistrict</small
            ><span v-if="!schooldistrict.active" style="color: red"
              >(Gedeactiveerd)</span
            >
          </h1>
        </template>
        <template v-slot:right>
          <dropdown placement="bottom-end">
            <i-button type="primary" ghost>
              Acties
              <icon type="md-more" />
            </i-button>

            <dropdown-menu slot="list" style="width: 220px">
              <router-link
                v-if="$user.context.isAdmin"
                :to="{
                  name: 'ResourceManagementSchooldistrictsSchooldistrictEdit',
                  params: {
                    resourceType: 'admin',
                    resourceId: 'default',
                    organisationType: 'schoolDistrict',
                    manageMethod: 'edit',
                  },
                }"
              >
                <sb-dropdown-button> Gegevens bewerken </sb-dropdown-button>
              </router-link>
              <sb-dropdown-button
                v-if="['ADMIN', 'PARTNER_MANAGER'].includes($user.role)"
                style="color: red"
                @click="$refs.SbDeleteResource.confirmRemoveResource()"
              >
                Schooldistrict verwijderen
              </sb-dropdown-button>
            </dropdown-menu>
          </dropdown>
        </template>
      </sb-page-header>

      <!-- helper component to delete a resource -->
      <sb-delete-resource
        ref="SbDeleteResource"
        v-model="loading"
        :resource-id="schooldistrict.id"
        entity="het schooldistrict"
      />

      <sb-tabbed-page v-if="schooldistrict" :data="schooldistrict" />
    </template>
  </div>
</template>

<script>
import SbLoading from '@/components/SbLoading';
import SbDeleteResource from '@/components/SbDeleteResource';

import SbPageHeader from '@/components/SbPageHeader';
import SbTabbedPage from '@/components/SbTabbedPage';

import GetOrganisationById from '@/graphql/queries/GetOrganisationById.gql';

export default {
  name: 'OnlineStudent',
  components: {
    SbPageHeader,
    SbTabbedPage,
    SbLoading,
    SbDeleteResource,
  },
  props: {
    organisationId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    adminContext() {
      return this.$user.context.isAdmin;
    },
  },
  methods: {},
  apollo: {
    schooldistrict: {
      query: GetOrganisationById,
      variables() {
        return {
          id: this.organisationId || this.$user.context.resourceId,
        };
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      },
      update(data) {
        console.log('SUPER');
        console.log(data.getOrganisationById);
        return data.getOrganisationById;
      },
    },
  },
};
</script>

<style lang="scss"></style>
