<template>
  <div></div>
</template>

<script>
export default {
  props: {
    mutation: {
      type: Object,
      required: false,
      default: () => {},
    },
    entity: {
      type: String,
      required: true,
    },
    // v-model for loading state
    value: {
      type: Boolean,
      default: false,
    },
    resourceId: {
      type: String,
      required: true,
    },
  },
  methods: {
    confirmRemoveResource() {
      this.$Modal.confirm({
        title: 'Let op!',
        content: `Weet je heel zeker dat je ${this.entity} wilt verwijderen? Dit kan een ingrijpende actie zijn en kan niet ongedaan worden gemaakt.`,
        closable: true,
        onOk: () => {
          setTimeout(() => {
            this.deleteResource();
          }, 300);
        },
      });
    },
    async deleteResource() {
      this.$emit('input', true);

      const res = await this.$apollo.mutate({
        mutation: this.mutation,
        variables: {
          id: this.resourceId,
        },
      });

      setTimeout(() => {
        this.$emit('input', false);
        this.$Modal.info({
          title: 'Gelukt',
          content: `${this.entity} is succesvol verwijderd`,
          onOk: () => {
            this.$router.navigateBack();
          },
        });
      }, 500);

      console.log(res);
    },
  },
};
</script>

<style></style>
