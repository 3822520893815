<template>
  <div>
    <sb-loading v-if="loading" />

    <div v-if="!loading && !school">Geen school gevonden</div>

    <template v-if="school">
      <sb-page-header>
        <template v-slot:left>
          <h1>
            {{ school.name }} <small>School</small>
            <span v-if="!school.active" style="color: red">
              (Gedeactiveerd)
            </span>
          </h1>
        </template>
        <template v-slot:right>
          <dropdown v-if="$user.role !== 'MANAGER'" placement="bottom-end">
            <i-button type="primary" ghost>
              Acties
              <icon type="md-more" />
            </i-button>

            <dropdown-menu slot="list" style="width: 220px">
              <router-link
                v-if="
                  $user.context.isAdmin ||
                  ($user.role === 'MANAGER' &&
                    $user.resourceType === 'SCHOOL_DISTRICT')
                "
                :to="{
                  name: 'ResourceManagementSchoolsSchoolAddEdit',
                  params: {
                    schoolManageId: school.id,
                    resourceType: 'admin',
                    resourceId: 'default',
                    organisationType: 'school',
                    manageMethod: 'edit',
                  },
                }"
              >
                <sb-dropdown-button> Gegevens bewerken </sb-dropdown-button>
              </router-link>
              <sb-dropdown-button
                v-if="
                  $user.role === 'ADMIN' ||
                  ($user.role === 'MANAGER' &&
                    $user.resourceType === 'SCHOOL_DISTRICT')
                "
                style="color: red"
                @click.native="deleteSchool(school.id)"
              >
                School verwijderen
              </sb-dropdown-button>
            </dropdown-menu>
          </dropdown>
        </template>
      </sb-page-header>
      <sb-tabbed-page v-if="school" :data="school" @refetch="refetch" />
    </template>
  </div>
</template>

<script>
import SbLoading from '@/components/SbLoading';
import SbPageHeader from '@/components/SbPageHeader';
import SbTabbedPage from '@/components/SbTabbedPage';
import { accessManagerMixin } from '@/mixins/accessManagerMixin';
import { vmConfirm } from '@/lib/vm-confirm';
import { vmConfirmWithToken } from '@/lib/vm-confirm-with-token.vue';
import gql from 'graphql-tag';

export default {
  components: {
    SbPageHeader,
    SbTabbedPage,
    SbLoading,
  },

  mixins: [accessManagerMixin],

  props: {
    organisationId: {
      type: String,
      default: null,
    },
  },

  computed: {
    loading() {
      return this.$apollo.queries?.school?.loading ?? false;
    },
  },

  methods: {
    refetch() {
      this.$apollo.queries.school.refetch();
    },

    async deleteSchool(id) {
      if (!this.school) return;

      try {
        const doDelete = await vmConfirmWithToken.call(
          this,
          this.school.name,
          'Weet je het zeker?',
          'Ben je absoluut zeker dat je deze school wil verwijderen?',
        );

        if (!doDelete) return;

        const deleted = this.$apollo.mutate({
          variables: { id },
          mutation: gql`
            mutation School_Delete($id: ID!) {
              deleteOrganisation(id: $id)
            }
          `,
        });

        if (deleted.errors) {
          return void this.$showGenericError();
        }

        this.$router.replace({
          name: 'ResourceManagementSchools',
          params: { ...this.$route.params },
        });
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      }
    },
  },
};
</script>

<style lang="scss"></style>
