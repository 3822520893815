<template>
  <div>
    <sb-spacer height="40" />

    <div style="display: flex; justify-content: flex-end">
      <i-button
        v-if="
          (data && $user.context.isAdmin) ||
          $user.role === 'SCHOOL_DISTRICT_MANAGER'
        "
        type="primary"
        @click="
          $router.push({
            name: 'ResourceManagementSchoolsSchoolAddEdit',
            params: {
              schoolManageId: data.id,
              resourceType: 'admin',
              resourceId: 'default',
              organisationType: 'school',
              manageMethod: 'edit',
            },
          })
        "
      >
        Gegevens bewerken
      </i-button>
    </div>

    <sb-organisation-data organisation-type="school" :data="data" />
    <sb-spacer height="40" />

    <sb-call-out v-if="$user.role === 'MANAGER'" style="max-width: 1000px">
      <template v-slot:left> ℹ️ </template>
      <template v-slot:right>
        Indien er onjuiste gegevens staan, neem dan contact op met
        <a href="mailto: support@brightskills.nl">support@brightskills.nl</a>.
      </template>
    </sb-call-out>

    <div></div>
    <sb-spacer height="100" />
    <!-- </sborganisationdata> -->
  </div>
</template>

<script>
import SbCallOut from '@/components/SbCallOut';
import SbOrganisationData from '@/components/SbOrganisationData';

import { formatDate } from '@/lib/date-helper.js';

export default {
  name: 'StudentData',
  components: {
    SbOrganisationData,
    SbCallOut,
  },

  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      formatDate,
    };
  },
};
</script>

<style></style>
