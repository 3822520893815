<template>
  <div>
    <sb-menu-tabs
      v-if="extraDepth"
      v-show="!$route.meta.hideTopMenu"
      :menu-type="menuType"
      :routes="routes(true)"
      :current-route="$route"
    />
    <sb-menu-tabs
      v-show="!$route.meta.hideTopMenu"
      :menu-type="extraDepth ? 'small' : 'default'"
      :routes="routes()"
      :current-route="$route"
    />
    <router-view :data="data" :type="type" @refetch="$emit('refetch')" />
  </div>
</template>

<script>
import SbMenuTabs from '@/components/SbMenuTabs';
import { getParent } from '@/lib/route-helper';

export default {
  components: {
    SbMenuTabs,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      default: null,
    },
    menuType: {
      default: 'default',
      validator: (value) => ['default', 'small'].includes(value),
    },
  },
  computed: {
    extraDepth() {
      return this.$route.matched.length === 4;
    },
  },

  watch: {
    '$route.params.trackId': {
      handler(newVal) {
        this.headerData = undefined;
      },
      deep: true,
    },
  },
  methods: {
    routes(useExtraDepth) {
      let parent = getParent(this.$route.name, this.$router.options.routes);
      if (useExtraDepth) {
        parent = getParent(parent.name, this.$router.options.routes);
      }

      return parent ? parent.children : [];
    },
  },
};
</script>

<style></style>
