<template>
  <div>
    <sb-spacer height="40" />

    <div style="display: flex; justify-content: flex-end">
      <i-button
        v-if="$user.context.isAdmin"
        type="primary"
        @click="
          $router.push({
            name: 'ResourceManagementSchooldistrictsSchooldistrictEdit',
            params: {
              resourceType: 'admin',
              resourceId: 'default',
              organisationType: 'schoolDistrict',
              manageMethod: 'edit',
            },
          })
        "
      >
        Gegevens bewerken
      </i-button>
    </div>

    <sb-organisation-data organisation-type="schoolDistrict" :data="data" />

    <sb-call-out
      v-if="$user.role === 'SCHOOL_DISTRICT_MANAGER'"
      style="max-width: 1000px"
    >
      <template v-slot:left> ℹ️ </template>
      <template v-slot:right>
        Indien er onjuiste gegevens staan, neem dan contact op met
        <a href="mailto: support@brightskills.nl">support@brightskills.nl</a>.
      </template>
    </sb-call-out>

    <sb-spacer height="100" />
  </div>
</template>

<script>
import SbCallOut from '@/components/SbCallOut';
import SbOrganisationData from '@/components/SbOrganisationData';

export default {
  name: 'StudentData',
  components: {
    SbCallOut,
    SbOrganisationData,
  },

  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style></style>
