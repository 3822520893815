<template>
  <div>
    <school v-if="resourceType() === 'SCHOOL' || resourceContext() === 'SCHOOL'" />
    <schooldistrict v-if="resourceType() === 'SCHOOL_DISTRICT' || resourceContext() === 'SCHOOL_DISTRICT'" />

    <div v-if="resourceContext() === 'ADMIN'">
      <sb-page-header>
        <template v-slot:left>
          <h1>Administratie</h1>
        </template>
      </sb-page-header>

      <tabs :animated="false">
        <tab-pane label="Gegevens">
          <sb-user-form
            default-mode="readonly"
            :default-data="me"
            :role="Role.ADMIN"
          />
        </tab-pane>
        <tab-pane :label="accessRequestsLabel">
          <access-requests />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import School from '@/views/School/School.vue';
import Schooldistrict from '@/views/Schooldistricts/Schooldistrict.vue';
import { getResourceType } from '@/lib/user-context-manager';
import SbPageHeader from '@/components/SbPageHeader.vue';
import AccessRequests from './AccessRequests.vue';
import { accessManagerMixin } from '@/mixins/accessManagerMixin';
import SbUserForm from '@/components/SbUserForm/SbUserForm.vue';
import { roleMixin } from '@/lib/role';
import gql from 'graphql-tag';

export default {
  components: {
    School,
    Schooldistrict,
    SbPageHeader,
    AccessRequests,
    SbUserForm,
  },

  mixins: [accessManagerMixin, roleMixin],

  computed: {
    accessRequestsLabel() {
      return (h) => {
        return h('div', [
          h('span', 'Toegangsverzoeken'),
          this.pendingAccessRequestsAmount
            ? h('div', { class: 'sb_access-requests-badge' }, [
                h('sb-badge', this.pendingAccessRequestsAmount),
              ])
            : undefined,
        ]);
      };
    },
  },

  methods: {
    resourceType() {
      return getResourceType();
    },
    resourceContext() {
      return this.$user.context.resourceType
    }
  },

  apollo: {
    me: {
      query: gql`
        query Resource_Me {
          me {
            id
            birthDate
            classYear
            email
            firstName
            gender
            lastName
            notes
            prefix
            secondaryEmails
            studentNumber
            username
            zelfredzaamheid
          }
        }
      `,
    },
  },
};
</script>

<style>
.sb_access-requests-badge {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(8px, 0);
}
</style>
