var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sb-spacer',{attrs:{"height":"40"}}),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[(
        (_vm.data && _vm.$user.context.isAdmin) ||
        _vm.$user.role === 'SCHOOL_DISTRICT_MANAGER'
      )?_c('i-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
          name: 'ResourceManagementSchoolsSchoolAddEdit',
          params: {
            schoolManageId: _vm.data.id,
            resourceType: 'admin',
            resourceId: 'default',
            organisationType: 'school',
            manageMethod: 'edit',
          },
        })}}},[_vm._v(" Gegevens bewerken ")]):_vm._e()],1),_c('sb-organisation-data',{attrs:{"organisation-type":"school","data":_vm.data}}),_c('sb-spacer',{attrs:{"height":"40"}}),(_vm.$user.role === 'MANAGER')?_c('sb-call-out',{staticStyle:{"max-width":"1000px"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_vm._v(" ℹ️ ")]},proxy:true},{key:"right",fn:function(){return [_vm._v(" Indien er onjuiste gegevens staan, neem dan contact op met "),_c('a',{attrs:{"href":"mailto: support@brightskills.nl"}},[_vm._v("support@brightskills.nl")]),_vm._v(". ")]},proxy:true}],null,false,1473255426)}):_vm._e(),_c('div'),_c('sb-spacer',{attrs:{"height":"100"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }