import { nonReactiveMembersMixin } from '../mixins/nonReactiveMembersMixin';

export const EducationType = Object.freeze({
  PRIMARY: 'PRIMARY',
  REMEDIAL_TEACHING: 'REMEDIAL_TEACHING',
  SECONDARY_EDUCATION: 'SECONDARY_EDUCATION',
  OTHER: 'OTHER',
});

/**
 * Get a human readable label for the corresponding `EducationType` member.
 * @param {string} type
 * @returns {string} card label
 */
export function getEducationTypeLabel(type) {
  if (!EducationType[type]) throw new Error(`Unkown type ${type}`);

  switch (type) {
    case EducationType.PRIMARY:
      return 'Basisschool';
    case EducationType.REMEDIAL_TEACHING:
      return 'Remedial Teaching Praktijk';
    case EducationType.SECONDARY_EDUCATION:
      return 'Voortgezet onderwijs';
    case EducationType.OTHER:
    default:
      return 'Anders';
  }
}

export const educationTypeMixin = {
  mixins: [nonReactiveMembersMixin(() => ({ EducationType }))],
  methods: { getEducationTypeLabel },
};
