<template>
  <div>
    <sb-loading v-if="loading" />
    <sb-page-header>
      <template v-slot:left>
        <h1>Toegangsverzoeken</h1>
      </template>

      <template v-slot:right>
        <i-button
          v-if="$user.role === 'ADMIN' && !$user.context.isAdmin"
          type="primary"
          ghost
          @click="createAccessRequest"
        >
          Toegangsverzoek indienen
          <icon type="md-add" />
        </i-button>
      </template>
    </sb-page-header>

    <sb-table-2
      :config="tableConfig"
      :data="accessRequests"
      :with-navigation="false"
    >
      <template slot="column-granted-at" slot-scope="{ content, row }">
        <template v-if="['MANAGER', 'SCHOOL'].includes($context)">
          <template
            v-if="content && row.status === AccessRequestStatus.GRANTED"
          >
            Verleend op {{ new Date(content).toLocaleDateString() }}
          </template>

          <template v-else-if="row.status === AccessRequestStatus.DENIED">
            Geweigerd
          </template>

          <div
            v-else-if="
              row.status === AccessRequestStatus.PENDING &&
              $user.role !== 'ADMIN'
            "
            class="table-action-buttons"
          >
            <button type="button" @click="acceptAccessRequest(row.id)">
              <sb-button size="small" button-type="green">
                Accepteren
              </sb-button>
            </button>

            <button type="button" @click="denyAccessRequest(row.id)">
              <sb-button size="small" button-type="red"> Weigeren </sb-button>
            </button>
          </div>
        </template>

        <template v-else>
          <button type="button" @click="cancelAccessRequest(row.id)">
            <sb-button size="small"> Annuleren </sb-button>
          </button>
        </template>
      </template>
    </sb-table-2>
  </div>
</template>

<script>
import { accessManagerMixin } from '@/mixins/accessManagerMixin';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { TableConfig } from '@/components/SbTable2/TableConfig';
import SbLoading from '@/components/SbLoading.vue';
import SbPageHeader from '@/components/SbPageHeader';
import SbTable2 from '@/components/SbTable2/SbTable2.vue';

export default {
  components: {
    SbPageHeader,
    SbTable2,
    SbLoading,
  },

  mixins: [
    accessManagerMixin,
    nonReactiveMembersMixin((self) => ({
      tableConfig: new TableConfig({
        columns: [
          {
            key: 'requester',
            header: 'Gebruiker',
            data: (row) => row.requester?.fullName,
          },
          {
            key: 'createdAt',
            header: 'Aangevraagd op',
            data: (row) => new Date(row.createdAt).toLocaleDateString(),
          },
          {
            key: 'grantedUntil',
            header: 'Verleend tot',
            data: (row) =>
              row.grantedUntil
                ? new Date(row.grantedUntil).toLocaleDateString()
                : '-',
          },
          {
            key: 'status',
            header: 'Status',
            data: (row) =>
              row.status ? self.getAccessRequestStatusLabel(row.status) : '-',
          },
          {
            key: 'grantedAt',
            header: 'Actie',
            width: ['MANAGER', 'SCHOOL'].includes(self.$context) ? 268 : 148,
          },
        ],
      }),
    })),
  ],

  props: {
    data: {
      type: Object,
      default: null,
    },
  },

  computed: {
    loading() {
      for (const query of Object.values(this.$apollo.queries)) {
        if (query.loading) return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-action-buttons {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 10px;
}
</style>
