<template>
  <div>
    <school-data
      v-if="resourceType() === 'SCHOOL' || resourceContext() === 'SCHOOL'"
      :data="data"
    />
    <schooldistrict-data
      v-if="
        resourceType() === 'SCHOOL_DISTRICT' ||
        resourceContext() === 'SCHOOL_DISTRICT'
      "
      :data="data"
    />
  </div>
</template>

<script>
import SchoolData from '@/views/School/SchoolData.vue';
import SchooldistrictData from '@/views/Schooldistricts/SchooldistrictData.vue';
import { getResourceType } from '@/lib/user-context-manager';

export default {
  components: {
    SchoolData,
    SchooldistrictData,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    resourceType() {
      return getResourceType();
    },
    resourceContext() {
      return this.$user.context.resourceType;
    },
  },
};
</script>

<style></style>
