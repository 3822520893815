<template>
  <div>
    <row :gutter="25" style="max-width: 1000px">
      <i-col :xs="{ span: 24 }" :lg="{ span: 12 }">
        <sb-divider title="Algemeen" />

        <sb-list>
          <sb-list-item label="id:">
            {{ data.id }}
          </sb-list-item>
          <sb-list-item label="Aangemaakt:">
            {{
              data.createdAt
                ? new Date(data.createdAt).toLocaleDateString()
                : '-'
            }}
          </sb-list-item>
          <sb-list-item label="Startdatum:">
            {{ formatDate(data.startDate) }}
          </sb-list-item>

          <sb-list-item label="Einddatum:">
            {{ formatDate(data.closingDate) }}
          </sb-list-item>
          <sb-list-item label="Verlengbeleid:">
            {{ formatRenewalPolicy(data.renewalPolicy) }}
          </sb-list-item>

          <sb-list-item label="Naam:">
            {{ data.name }}
          </sb-list-item>
          <sb-list-item
            v-if="organisationType === 'school'"
            label="Onderwijstype:"
          >
            {{
              data.educationType
                ? getEducationTypeLabel(data.educationType)
                : ''
            }}
          </sb-list-item>
          <sb-list-item label="BRIN:">
            {{ data.brin }}
          </sb-list-item>
          <sb-list-item label="KVK-nummer:">
            {{ data.chamberOfCommerceNumber }}
          </sb-list-item>
          <!-- <SbListItem label="BRIN nummer:">
            {{ data.brinNumber || '-' }}
          </SbListItem> -->

          <sb-list-item label="Contactpersoon:">
            {{ data.contact.name }}
          </sb-list-item>
        </sb-list>

        <template v-if="data.organisationType === 'SCHOOL'">
          <sb-divider title="Bovenschoolse organisatie" />
          <template v-if="data.parentOrganisation">
            <sb-list-item label="Schooldistrict:">
              {{ data.parentOrganisation.name }}
            </sb-list-item>
            <sb-list-item label="Beheert licenties:">
              {{ data.parentOrganisation.invoiceSelf ? 'Ja' : 'Nee' }}
            </sb-list-item>
          </template>
          <template v-else>
            <p>
              De school wordt niet beheerd door een bovenschoolse organisatie
            </p>
          </template>
        </template>

        <sb-list>
          <sb-list-item
            v-if="data.organisationType === 'SCHOOL_DISTRICT'"
            label="Beheert seats:"
          >
            {{ data.invoiceSelf ? 'Ja' : 'Nee' }}
          </sb-list-item>

          <template v-if="showSeatsStatus">
            <sb-list-item label="Aantal seats:">
              {{ data.seats }}
            </sb-list-item>
            <sb-list-item label="Gebruikte seats:">
              {{ data.takenSeats }}
            </sb-list-item>
          </template>
        </sb-list>
      </i-col>

      <i-col :xs="{ span: 24 }" :lg="{ span: 12 }">
        <sb-divider title="Bezoekadres" />

        <sb-list>
          <sb-list-item label="Straatnaam:">
            {{ data.address.street }}
          </sb-list-item>
          <sb-list-item label="Huisnummer:">
            {{ data.address.streetNumber }}
          </sb-list-item>
          <sb-list-item label="Postcode:">
            {{ data.address.postalCode }}
          </sb-list-item>
          <sb-list-item label="Stad:">
            {{ data.address.city }}
          </sb-list-item>
          <sb-list-item label="Land:">
            {{ country }}
          </sb-list-item>
        </sb-list>

        <sb-divider title="Contactpersoon" />

        <sb-list-item label="Naam:">
          {{ data.contact.name }}
        </sb-list-item>
        <sb-list-item label="E-mailadres:">
          {{ data.contact.email }}
        </sb-list-item>
        <sb-list-item label="Telefoonnummer:">
          {{ data.contact.phone }}
        </sb-list-item>
      </i-col>
    </row>
    <sb-spacer height="40" />
    <row :gutter="25" style="max-width: 1000px">
      <i-col :xs="{ span: 24 }" :lg="{ span: 12 }"> </i-col>
    </row>
  </div>
</template>

<script>
import SbDivider from '@/components/SbDivider';
import SbList from '@/components/SbList';
import SbListItem from '@/components/SbListItem';
import Countries from '@/graphql/queries/Countries.gql';
import EducationTypes from '@/graphql/queries/EducationTypes.gql';
import { educationTypeMixin } from '@/lib/education-type';

export default {
  components: {
    SbDivider,
    SbList,
    SbListItem,
  },

  mixins: [educationTypeMixin],

  props: {
    data: {
      type: Object,
      default: null,
    },
    organisationType: {
      type: String,
      default: null,
    },
  },
  apollo: {
    educationTypes: {
      query: EducationTypes,
    },
    countries: {
      query: Countries,
    },
  },
  computed: {
    showSeatsStatus() {
      return (
        (this.data?.organisationType === 'SCHOOL' &&
          this.data?.parentOrganisation?.invoiceSelf === false) ||
        (this.data?.organisationType === 'SCHOOL_DISTRICT' &&
          this.data?.invoiceSelf === true)
      );
    },

    country() {
      if (!this.countries) return this.data.country;
      return this.countries.find(
        (country) => country.code === this.data.address.country,
      ).label;
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return '-';
      return new Date(date).toLocaleDateString();
    },

    formatRenewalPolicy(renewalPolicy) {
      switch (renewalPolicy) {
        case 'NONE':
          return 'Geen';
        case 'MONTH':
          return 'Maand';
        case 'YEAR':
          return 'Jaar';
        default:
          return '-';
      }
    },
  },
};
</script>

<style></style>
